import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { ZoneSettingOptions } from '../models/zoneSetting';

export class ZoneSettingsConstants {
  public static REDZONETYPE_RADIO_OPTIONS: RadioButtonValue[] = [
    {
      value: 0,
      name: 'Disabled',
      isDefault: false,
    },
    {
      value: 1,
      name: 'Static',
      isDefault: false,
    },
    {
      value: 2,
      name: 'Dynamic',
      isDefault: false,
    },
  ];

  public static NONBSPTAGSOPTIONS: ZoneSettingOptions[] = [
    {
      label: 'Block velocity upper bound',
      unit: '(m/s)',
      tagname: 'block_velocity_upper_bound',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block velocity lower bound',
      unit: '(m/s)',
      tagname: 'block_velocity_lower_bound',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block position upper bound',
      unit: '(m)',
      tagname: 'block_position_upper_bound',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block position lower bound',
      unit: '(m)',
      info: '',
      tagname: 'block_position_lower_bound',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'RPM threshold',
      unit: '(rpm)',
      tagname: 'rpm_threshold',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
    {
      label: 'Standpipe pressure threshold',
      unit: '(Pa)',
      tagname: 'standpipe_pressure_threshold',
      info: '',
      value: 0,
      zoneUpdatedValue: '0',
    },
  ];

  public static BSPTAGSOPTIONS: ZoneSettingOptions[] = [
    {
      label: 'Block moving down - hook speed',
      unit: '(m/s)',
      tagname: 'tripping_in_hook_speed',
      value: 0,
      info: 'Hook Speed Info: Input Hook Speed value to determine what speed in m/s trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block moving down - block position',
      unit: '(m)',
      tagname: 'tripping_in_block_position',
      value: 0,
      info: 'Block Position Info: Input Block Position value where redzone will be deactivated whenever block position is below value.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block moving down - block weight',
      unit: '(N)',
      tagname: 'tripping_in_hookload',
      value: 0,
      info: 'Block Weight: Input Block Weight value in newton to trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block moving up - hook speed',
      unit: '(m/s)',
      tagname: 'tripping_out_hook_speed',
      value: 0,
      info: 'Hook Speed Info: Input Hook Speed value to determine what speed in m/s trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Block moving up - block weight',
      unit: '(N)',
      tagname: 'tripping_out_hookload',
      value: 0,
      info: 'Block Weight: Input Block Weight value in newton to trigger redzone',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Circulating - cement pump',
      tagname: 'circulating_cement_pump',
      unit: '(m3/s)',
      value: 0,
      info: 'Cement Pump: Input Cement Pump value to trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Circulating - block weight',
      unit: '(N)',
      tagname: 'circulating_hookload',
      value: 0,
      info: 'Block Weight: Input Block Weight value in newton to trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Circulating flow pump',
      unit: '(m3/s)',
      tagname: 'circulating_flow_pump',
      value: 0,
      info: 'Flow Pump: Input Flow Pump value to trigger redzone.',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Drilling/Rotating torque threshold',
      unit: '(N.m)',
      tagname: 'dril_rotate_torque_threshold',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Drilling/Rotating RPM threshold',
      unit: '(rpm)',
      tagname: 'dril_rotate_rpm_threshold',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Drilling/Rotate flow pump',
      unit: '(m3/s)',
      tagname: 'dril_rotate_flow_pump',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Driling/Rotating standpipe pressure threshold',
      unit: '(Pa)',
      tagname: 'dril_rotate_standpipe_pressure_threshold',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
    {
      label: 'Drilling/Rotate bit on bottom threshold',
      unit: '(m)',
      tagname: 'dril_rotate_bit_on_bottom_threshold',
      value: 0,
      info: '',
      zoneUpdatedValue: '0',
    },
  ];
  public static successMessage = 'Saved Succesfully!';
  public static errorMessage = 'Error occured while saving event review.';
  public static zone_errorMessage = 'Error occured while saving zone settings.';
  public static zoneSuccessMessage = 'Zone settings saved succesfully!';
  public static NonBSPTags: any = {
    block_velocity_upper_bound: 0,
    block_velocity_lower_bound: 0,
    block_position_upper_bound: 0,
    block_position_lower_bound: 0,
    rpm_threshold: 0,
    standpipe_pressure_threshold: 0,
  };

  public static bspTags: any = {
    tripping_in_hook_speed: 0,
    tripping_in_block_position: 0,
    tripping_in_hookload: 0,
    tripping_out_hook_speed: 0,
    tripping_out_hookload: 0,
    circulating_cement_pump: 0,
    circulating_hookload: 0,
    circulating_flow_pump: 0,
    data_interval: 0,
    dril_rotate_torque_threshold: 0,
    dril_rotate_rpm_threshold: 0,
    dril_rotate_flow_pump: 0,
    dril_rotate_standpipe_pressure_threshold: 0,
    dril_rotate_bit_on_bottom_threshold: 0,
  };
}
