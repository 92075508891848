<div class="vi_camera-profile_alerts-container" id="vi_camera_profile_alerts_container">
  <div class="alert-head">
    <div class="alert-heading">
      <mat-checkbox
        class="checkbox"
        id="vi_camera_profile_checkbox"
        [(ngModel)]="checkAll"
        *ngIf="viewMode === 'grid'"
        [disabled]="violationDataList && !violationDataList.length"
        (change)="checkAllFiles()"></mat-checkbox>
      <h4 [ngClass]="{ 'alert-head': viewMode === 'grid' }">Events</h4>
      <button
        slb-button
        class="download-btn"
        id="vi_camera_profile_alerts_download_csv"
        (click)="downloadCsvEventData()"
        [disabled]="(violationDataList && !violationDataList.length) || isDownloadStarted">
        Download CSV
      </button>
      <button
        slb-button
        class="download-btn"
        id="vi_camera_profile_alerts_download"
        (click)="downloadSelectedFile()"
        [disabled]="selectedFiles && !selectedFiles.length">
        Download
      </button>
      <button
        slb-button
        class="download-btn"
        id="vi_camera_profile_alerts_review"
        (click)="openReviewPopup()"
        [disabled]="selectedFiles && !selectedFiles.length">
        Review
      </button>
      <mat-form-field class="review-filter" appearance="outline" id="vi_camera_profile_filter">
        <mat-select [(ngModel)]="reviewCategory" (selectionChange)="getFilteredReviews()" hideSingleSelectionIndicator="true">
          <mat-option *ngFor="let reviewCategory of reviewFilterList" [value]="reviewCategory">
            {{ reviewCategory }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="violation-holder">
    <ul class="violations-list">
      <li id="vi_camera_profile_alerts_review">
        <span><mat-icon svgIcon="check" class="check-icon top-icons">check</mat-icon> </span>
        <span class="icon-title">Reviewed events</span>
      </li>
      <div class="d-flex" *ngIf="viewMode === 'grid'; else table">
        <li id="vi_camera_profile_alerts_redzone">
          <span class="people-redzone bg-setting"></span>
          <span class="svg-title" (click)="strikeRedzone = !strikeRedzone; assignEvent()" [ngClass]="strikeRedzone ? 'line' : 'unline'"
            >Red zone event</span
          >
        </li>
        <li id="vi_camera_profile_alerts_violation">
          <span class="ppe-violations bg-setting"></span>
          <span class="svg-title" (click)="strikePPE = !strikePPE; assignEvent()" [ngClass]="strikePPE ? 'line' : 'unline'">PPE event</span>
        </li>
      </div>
      <ng-template #table>
        <li id="vi_camera_profile_alerts_not_review">
          <span><mat-icon svgIcon="close" class="close-icon top-icons">close</mat-icon></span>
          <span class="icon-title">Not reviewed events</span>
        </li>
      </ng-template>
    </ul>
    <div class="mb-toggle-group-mode">
      <mat-button-toggle-group
        disableRipple="true"
        (change)="switchDays($event)"
        [value]="dayMode"
        id="vi_camera_profile_alerts_day_group"
        class="days-button-group">
        <mat-button-toggle value="hour">Hour </mat-button-toggle>
        <mat-button-toggle value="day"> Day </mat-button-toggle>
        <mat-button-toggle value="week"> Week </mat-button-toggle>
        <mat-button-toggle value="custom" class="custom" (click)="customClick()"><span>Custom</span> </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="mb-toggle-group-mode">
      <mat-button-toggle-group
        disableRipple="true"
        (change)="switchView($event)"
        id="vi_camera_profile_alerts_view_mode"
        [value]="viewMode"
        class="button-group">
        <mat-button-toggle value="grid"> Grid view </mat-button-toggle>
        <mat-button-toggle value="table"> Table view </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="date-container">
    <p>
      You are seeing data for {{ startDate | date : 'MM/dd/yyyy HH:mm:ss' : refreshedTimezone }} to
      {{ endDate | date : 'MM/dd/yyyy HH:mm:ss' : refreshedTimezone }}
    </p>
  </div>
  <p class="no-data" *ngIf="violationDataList && !violationDataList.length && !isLoading; else loader">No data available</p>

  <ng-template #loader>
    <div class="spinner" *ngIf="isLoading; else loadData">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
  <ng-template #loadData>
    <div *ngIf="viewMode === 'grid'; else showTable">
      <app-alerts-grid
        [isLoading]="isLoading"
        [gridData]="violationDataList"
        id="vi_camera_profile_alerts_grid"
        [gatewayId]="gatewayId"
        [controllerId]="controllerId"
        [paginatedData]="paginatedData"
        (selectedData)="getSelectedFiles($event)"
        (pageData)="getPaginatedData($event)"
        [eventId]="eventId"
        (cameraPopOverClosed)="setEventId()"
        [totalNoRecords]="totalRecords"></app-alerts-grid>
    </div>
    <ng-template #showTable>
      <app-alerts-table
        id="vi_camera_profile_alerts_table"
        [isLoading]="isLoading"
        [gridData]="violationDataList"
        [gatewayId]="gatewayId"
        [controllerId]="controllerId"
        (selectedData)="getSelectedFiles($event)"
        (pageData)="getPaginatedData($event)"
        [paginatedData]="paginatedData"
        [totalNoRecords]="totalRecords"></app-alerts-table>
    </ng-template>
  </ng-template>
</div>
