<div class="latest-camera-container" *ngIf="cameraDetails" [ngClass]="{ 'full-screen': isFullscreen, 'full-screen-side': isFullscreen }">
  <div class="reduce-screen" *ngIf="!isFullscreen">
    <div class="header-container">
      <button class="icon-button">
        <mat-icon
          svgIcon="fullscreen"
          class="cursor-pointer"
          id="latest_camera_popover_toggle_not_fullScreen"
          *ngIf="!noImagePreview"
          (click)="toggleFullscreen()"></mat-icon>
      </button>
      <div class="camera-name">
        <span
          ><mat-icon
            svgIcon="oil-bitumen"
            [ngClass]="{
              active: cameraStatus === 0,
              inactive: cameraStatus === 1,
              disconnect: cameraStatus === 2
            }"
            id="latest_camera_popover_oil_bitumen_icon"></mat-icon
        ></span>
        <span class="rig-title" id="latest_camera_popover_rig_title">{{ cameraDetails.cameraName }}</span>
      </div>
      <mat-icon svgIcon="close" class="close-icon cursor-pointer" id="latest_camera_popover_close_icon" (click)="onClose()">close</mat-icon>
    </div>
    <div class="reduce-event-carousel">
      <div class="event-container">
        <div class="event-timestamp" id="latest_camera_popover_event_timestamp">
          Event time: {{ cameraDetails.timestamp | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}
        </div>
      </div>
      <slb-carousel
        class="carousel-wrapper"
        [value]="media"
        [circular]="false"
        [showControls]="true"
        [showIndicators]="true"
        [breakPoint]="1"
        [slidesToShow]="1"
        [slidesToScroll]="media ? media.length : 0"
        [activeSlideIndex]="currentIndex ? currentIndex : 0"
        (onSlide)="getCurrentIndex($event)">
        <ng-template slbTemplate="item" let-item>
          <div class="carousel-item-wrapper">
            <div class="image-container" *ngIf="item && item.type === 'image'">
              <img
                *ngIf="item.fileUrl && !item.fileUrl.includes('mp4'); else errorFileImage"
                [src]="item.fileUrl + '/?token=' + sessionToken"
                (error)="reducedImg.src = '../../assets/no-preview.svg'"
                #reducedImg
                class="image-src"
                appImageStyle
                id="latest_camera_popover_image_src" />
              <mat-icon
                id="latest_camera_popover_fullscreen_icon"
                class="img-fullscreen-icon"
                (click)="showImageInFullscreen(item.fileUrl)"
                svgIcon="fullscreen"
                title="Fullscreen"></mat-icon>
              <ng-template #errorFileImage>
                <img src="../../assets/no-preview.svg" class="image-src" id="latest_camera_popover_image_src" appImageStyle />
              </ng-template>
            </div>
            <div class="image-container" *ngIf="item.type === 'video'">
              <video class="video-player" #videoPlayer class="image-src" autoplay id="latest_camera_popover_controls" controls loop>
                <source src="{{ item.fileUrl }}/?token={{ sessionToken }}" type="video/mp4" />
                <track id="enTrack" label="English" kind="subtitles" srclang="en-us" default />
              </video>
            </div>
            <div class="carousel-item-img"></div>
          </div>
        </ng-template>
      </slb-carousel>
      <div class="more-info">
        <div>
          <div class="rig-details" id="latest_camera_popover_rig_details">
            <div class="content">
              <p
                *ngIf="
                  !cameraDetails ||
                    !cameraDetails.rigState ||
                    (cameraDetails.rigState && cameraDetails.rigState === 'Red zone static') ||
                    (cameraDetails && !cameraDetails.peopleInsideRedZone && !cameraDetails.ppeViolations);
                  else rigData
                ">
                <img src="assets/icons/inside_redzone.svg" class="rig-icon" />
                Red zone static
              </p>
              <ng-template #rigData>
                <p class="camera-info">
                  <img
                    src="{{
                      cameraDetails && cameraDetails.peopleInsideRedZone
                        ? 'assets/icons/inside_redzone.svg'
                        : 'assets/icons/ppe_violations.svg'
                    }}"
                    class="rig-icon" />
                  <span class="label">{{
                    cameraDetails && cameraDetails.peopleInsideRedZone > 0
                      ? 'Rig floor Red zone
                    state: '
                      : 'Missing: '
                  }}</span>
                  <span matTooltip="{{ cameraDetails.rigState }}" class="rig-info">{{ cameraDetails.rigState }}</span>
                </p>
              </ng-template>
            </div>
          </div>
          <hr />
          <div class="comment-highlight" [ngClass]="{ highlighter: fromFullscreen }">
            <ng-container *ngTemplateOutlet="commentContent"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <hr />
      <div class="actions">
        <button
          slb-button
          class="action-buttons"
          id="latest_camera_popover_previous_btn"
          (click)="onNextPrevClick('prev')"
          [disabled]="!hasPrevious">
          <mat-icon svgIcon="dock-navigation-expand" class="button-icons"></mat-icon>
          Previous
        </button>
        <mat-icon
          svgIcon="feedback"
          class="cursor-pointer action-icons"
          (click)="openReview(cameraDetails)"
          id="latest_camera_popover_review_btn"
          >Review</mat-icon
        >
        <mat-icon svgIcon="send-2" class="cursor-pointer action-icons" (click)="openEmail()" id="latest_camera_popover_email_btn"
          >Email</mat-icon
        >
        <mat-icon svgIcon="download" class="cursor-pointer action-icons" (click)="downloadImages()" id="latest_camera_popover_download_btn"
          >Download</mat-icon
        >
        <button
          slb-button
          (click)="onNextPrevClick('next')"
          id="latest_camera_popover_next_btn"
          class="next-action-buttons"
          [disabled]="!hasNext">
          <mat-icon svgIcon="dock-navigation-collapse" class="button-icons"></mat-icon> Next
        </button>
      </div>
    </div>
  </div>
  <div class="full-screen-container" *ngIf="isFullscreen">
    <div class="fullscreen-header-container">
      <button class="icon-button">
        <mat-icon
          svgIcon="reduce-screen"
          class="cursor-pointer"
          (click)="toggleFullscreen(); fromFullscreen = true"
          id="latest_camera_popover_toggle_fullscreen"
          >fullscreen</mat-icon
        >
      </button>
      <div>
        <span
          ><mat-icon
            svgIcon="oil-bitumen"
            [ngClass]="{
              active: cameraStatus === 0,
              inactive: cameraStatus === 1,
              disconnect: cameraStatus === 2
            }"
            id="latest_camera_popover_oil_bitumen_icon_fullscreen"></mat-icon
        ></span>
        <span class="fullscreen-rig-title" id="latest_camera_popover_rig_title_fullscreen">{{ cameraDetails.cameraName }}</span>
      </div>
      <mat-icon
        svgIcon="close"
        class="fullscreen-close-icon cursor-pointer"
        id="latest_camera_popover_close_icon_fullscreen"
        (click)="onClose()"
        >close</mat-icon
      >
    </div>
    <div class="fullscreen-event-container">
      <div class="left-container">
        <app-carousel
          [media]="media"
          [showIndicators]="false"
          [selectedIndex]="currentIndex"
          [showControls]="true"
          (activeIndex)="setCurrentIndex($event)"></app-carousel>
      </div>
      <div class="right-container">
        <div class="fullscreen-timestamp-container" id="latest_camera_popover_fullscreen_timestamp_container">
          <div class="fullscreen-event-timestamp" id="latest_camera_popover_fullscreen_event_timestamp">
            Event time: {{ cameraDetails.timestamp | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}
          </div>
          <!-- <a class="fullscreen-event-view">View More</a> -->
        </div>
        <div [ngClass]="media.length === 2 ? 'image-grid' : 'no-grid'">
          <div *ngFor="let cameraData of media | slice : 0 : 2" class="grid-container">
            <img
              *ngIf="cameraData.type === 'image' && cameraData.fileUrl && !cameraData.fileUrl.includes('mp4'); else errorImage"
              id="latest_camera_popover_fullscreen_image_item"
              [ngSrc]="cameraData.fileUrl + '/?token=' + sessionToken"
              (error)="img.src = '../../assets/no-preview.svg'"
              priority
              width="300"
              height="200"
              #img
              class="image-item"
              [ngClass]="currentIndex === 0 ? 'highlight' : 'no-highlight'" />
            <mat-icon
              *ngIf="cameraData.type === 'image' && cameraData.fileUrl && !cameraData.fileUrl.includes('mp4')"
              class="img-fullscreen-icon"
              id="latest_camera_popover_grid_fullscreen_icon"
              (click)="showImageInFullscreen(cameraData.fileUrl)"
              svgIcon="fullscreen"
              title="Fullscreen"></mat-icon>
            <ng-template #errorImage>
              <img
                *ngIf="cameraData.type === 'image'"
                id="latest_camera_popover_fullscreen_image_item"
                ngSrc="../../assets//no-preview.svg"
                priority
                width="300"
                height="200"
                class="image-item"
                [ngClass]="currentIndex === 0 ? 'highlight' : 'no-highlight'" />
            </ng-template>
            <video
              *ngIf="cameraData.type === 'video'"
              #videoPlayer
              id="latest_camera_popover_fullscreen_video_item"
              class="video-player"
              class="image-item"
              autoplay
              controls
              loop
              [ngClass]="currentIndex === 1 ? 'highlight' : 'no-highlight'">
              <source src="{{ cameraData.fileUrl }}/?token={{ sessionToken }}" type="video/mp4" />
              <track id="enTrack" label="English" kind="subtitles" srclang="en-us" default />
            </video>
          </div>
        </div>
        <div class="fullscreen-more-info">
          <div class="reviewed-event-details">
            <div class="rig-details" id="fullscreen_latest_camera_popover_rig_details">
              <div class="content">
                <p
                  *ngIf="
                    !cameraDetails ||
                      !cameraDetails.rigState ||
                      cameraDetails.rigState === 'Red zone static' ||
                      (cameraDetails && !cameraDetails.peopleInsideRedZone && !cameraDetails.ppeViolations);
                    else rigData
                  ">
                  <img src="assets/icons/inside_redzone.svg" class="rig-icon" />
                  Red zone static
                </p>
                <ng-template #rigData>
                  <p class="camera-info">
                    <img
                      src="{{
                        cameraDetails && cameraDetails.peopleInsideRedZone
                          ? 'assets/icons/inside_redzone.svg'
                          : 'assets/icons/ppe_violations.svg'
                      }}"
                      class="rig-icon" />
                    <span class="label">{{
                      cameraDetails && cameraDetails.peopleInsideRedZone > 0
                        ? 'Rig floor Red zone
                      state: '
                        : 'Missing: '
                    }}</span>
                    <span matTooltip="{{ cameraDetails.rigState }}" class="rig-info">{{ cameraDetails.rigState }}</span>
                  </p>
                </ng-template>
              </div>
              <hr />
            </div>
            <div class="comment-highlight fullscreen-comment">
              <ng-container *ngTemplateOutlet="commentContent"></ng-container>
            </div>
          </div>
        </div>
        <div class="fullscreen-footer">
          <hr />
          <div class="actions">
            <button
              slb-button
              class="action-buttons"
              id="latest_camera_popover_previous_fullscreen"
              (click)="onNextPrevClick('prev')"
              [disabled]="!hasPrevious">
              <mat-icon svgIcon="dock-navigation-expand" class="button-icons"></mat-icon>
              Previous
            </button>
            <mat-icon
              svgIcon="feedback"
              class="cursor-pointer action-icons"
              (click)="openReview(cameraDetails)"
              id="latest_camera_popover_review_fullscreen"
              >Review</mat-icon
            >
            <mat-icon svgIcon="send-2" class="cursor-pointer action-icons" (click)="openEmail()" id="latest_camera_popover_email_fullscreen"
              >Email</mat-icon
            >
            <mat-icon
              svgIcon="download"
              class="cursor-pointer action-icons"
              (click)="downloadImages()"
              id="latest_camera_popover_download_fullscreen"
              >Download</mat-icon
            >
            <button
              slb-button
              (click)="onNextPrevClick('next')"
              id="latest_camera_popover_next_fullscreen"
              class="next-action-buttons"
              [disabled]="!hasNext">
              <mat-icon svgIcon="dock-navigation-collapse" class="button-icons"></mat-icon> Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="img-background" *ngIf="isImgFullScreen">
  <div [ngClass]="{ show: isImgFullScreen, hide: !isImgFullScreen }" *ngIf="isImgFullScreen">
    <img [src]="fullScreeenImgSrc" />
    <button class="close-fullscreen" (click)="hideImageInFullscreen()">
      <mat-icon svgIcon="close" class="img-close-icon" id="latest_camera_popover_close_fullscreen">close</mat-icon>
    </button>
  </div>
</div>
<ng-template #commentContent>
  <div class="comment-list" *ngIf="cameraDetails.peopleInsideRedZone > 0 && commentDetails.length > 0 && cameraDetails?.groupId !== 'bsp'">
    <ul>
      <li [ngClass]="{ red: item.color === 'red' }" *ngFor="let item of commentDetails">
        <span class="key">{{ item.key }}: </span>
        <span class="value">{{ item.value }}</span>
      </li>
    </ul>
  </div>
  <hr
    *ngIf="cameraDetails.peopleInsideRedZone > 0 && commentDetails.length > 0 && cameraDetails.comment && cameraDetails.isReviewed"
    class="content" />
  <div class="review-details" id="latest_camera_popover_review_details" *ngIf="cameraDetails.isReviewed">
    <p>
      <span><mat-icon svgIcon="check" class="check-icon top-icons">check</mat-icon> </span>
      <span class="label icon-title">Reviewed event</span>
    </p>
    <p>
      <span class="label">Category: </span
      ><span class="value">{{
        cameraDetails.category === 0
          ? 'Yet to be reviewed'
          : cameraDetails.category === 1
          ? 'Reviewed, invalid'
          : cameraDetails.category === 2
          ? 'Reviewed, as system detected'
          : cameraDetails.category === 3
          ? 'Reviewed, human behavior'
          : 'Exclude'
      }}</span>
    </p>
    <p>
      <span class="label">Flag: </span>
      <span class="value">{{
        cameraDetails.flag === 0
          ? 'Valid'
          : cameraDetails.flag === 1
          ? 'Blurred camera'
          : cameraDetails.flag === 2
          ? 'Missing People Bounding Boxes'
          : cameraDetails.flag === 3
          ? 'Duplicated People Bounding Boxes'
          : cameraDetails.flag === 4
          ? 'Invalid - PPE False Positive'
          : cameraDetails.flag === 5
          ? 'Invalid - PPE Wrong Person'
          : cameraDetails.flag === 6
          ? 'Invalid - Ghost Person'
          : cameraDetails.flag === 7
          ? 'Invalid - Delayed Rig Data'
          : cameraDetails.flag === 8
          ? 'Invalid - Person not in Zone'
          : cameraDetails.flag
      }}</span>
    </p>
    <p>
      <span class="label">Remark: </span><span class="value">{{ cameraDetails.reviewComment }}</span>
    </p>
  </div>
</ng-template>
