export const environment = {
  production: false,
  apiBaseUrl: 'https://evd.agorava.cloud.slb-ds.com',
  isAutomationTesting: false,
  appConfigApiUrl: '',
  identityProviderUrl: 'https://p4d.csi.cloud.slb-ds.com/v2',
  identityProviderClientKey: '23d7874980c47cdd6329de9955471bbc',
  identityProviderClientSecret: '6836daa759214bafbf2eac70633a13309bb8af45997858cbdd',
  identityProviderRedirectUrl: 'https://evd.agorava.cloud.slb-ds.com/login/logincallback',
};
