import { Chart } from '../models/chart';
import { EventDetails } from '../models/eventDetails';
import * as Highcharts from 'highcharts';

export const EVENT_TABLE_HEADING = ['select', 'timestamp', 'peopleInsideRedZone', 'isReviewed', 'category', 'flag', 'action'];
export const EVENT_CSV_DOWNLOAD_HEADING = [
  'timestamp',
  'eventId',
  'type',
  'peopleInsideRedZone',
  'imageFilename',
  'videoFilename',
  'reason',
  'isReviewed',
  'category',
  'flag',
  'reviewComment',
];

export const EVENT_PAGINATION_OPTIONS = [5, 10, 15, 25, 50];

export const CAMERAPROFILEERROR = 'Error while receiving camera profile details';
export const EVENTERROR = 'Error while receiving event details';
export const CHART_ERROR = 'Error while receiving chart details';
export const FILE_DOWNLOAD_ERROR = 'No file is available to download';
export const EVENT_FILTER = ['All', 'Reviewed', 'Not reviewed', 'Excluded'];
export const VIDEO = 'video';
export const REDZONE = 'redZone';
export const PPE = 'ppe';
export const ALL = 'all';
export const EXCLUDED = 'excluded';

export const CHARTDATA: Chart = {
  labels: [],
  legend: {
    align: 'center',
    verticalAlign: 'top',
    width: 900,
    itemWidth: 200,
  },
  datasets: [
    {
      data: [],
      backgroundColor: ['#EE5E64', '#3097A7', '#C7D0D8'],
      borderColor: ['#EE5E64', '#3097A7', '#C7D0D8'],
      borderWidth: 1,
      hoverBorderColor: ['#EE5E64', '#3097A7', '#C7D0D8'],
      hoverBackgroundColor: ['#EE5E64', '#3097A7', '#C7D0D8'],
      pointBackgroundColor: ['#EE5E64', '#3097A7', '#C7D0D8'],
      pointBorderColor: ['#fff', '#fff', '#fff'],
      pointHoverBackgroundColor: ['#EE5E64', '#3097A7', '#C7D0D8'],
      pointHoverBorderColor: ['#EE5E64', '#3097A7', '#C7D0D8'],
    },
  ],
};

export const MULTIEVENTDATA: EventDetails = {
  eventId: 0,
  timestamp: '2023-06-14T22:39:58Z',
  isReviewed: false,
  thumbnailFileUrl: '../../../assets/camera-profile/7549774341816899755-redzone_frame1673597504.jpg',
  peopleInsideRedZone: 0,
  ppeViolations: 0,
  machineMovementCompliance: 0,
  flag: null,
  category: null,
  rigState: '',
  comment: '',
  reviewComment: '',
  media: [],
};

export const EVENTCOLORS = [
  { name: 'Mud Circulation', color: '#d47500' },
  { name: 'Block Moving Down', color: '#d15299' },
  { name: 'Block Moving Up', color: '#051465' },
  { name: 'Drilling Rotation', color: '#1a237ebd' },
  { name: 'Red Zone Static', color: '#2196f3' },
  { name: 'High Visibility Vest', color: '#6a38bf' },
  { name: 'Helmet Detection', color: '#3bab45' },
  { name: 'Glove Detection', color: '#91c3dc' },
  { name: 'Coverall Detection', color: '#f0ea18' },
  { name: 'Block Movement', color: '#BDB76B' },
  { name: 'Block Rotation', color: '#00FFFF' },
  { name: 'High Pressure', color: '#DB7093' },
  { name: 'Reviewed', color: '#67c469' },
  { name: 'Not Reviewed', color: '#ffd200' },
];

export const HIGHCHARTCHARTDATA: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: '',
  },
  chart: {
    plotBorderWidth: 0,
    plotShadow: false,
    backgroundColor: '#fcfcfd',
  },
  legend: {
    enabled: true,
    align: 'right',
    verticalAlign: 'middle',
    itemMarginBottom: 5,
    itemMarginTop: 0,
    symbolHeight: 12,
    symbolWidth: 12,
    symbolPadding: 5,
    symbolRadius: 0,
    itemStyle: {
      fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
      fontSize: '10px',
      fontWeight: '500',
    },
  },
  plotOptions: {
    pie: {
      borderWidth: 0,
      borderRadius: 0,
    },
  },
  tooltip: {
    pointFormat: '<b>{point.y}</b>' + '<b>{point.count}</b><br/>',
  },
  series: [],
};

export const EVENT_STATUS_CHART_OPTION: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: '',
  },
  chart: {
    plotBorderWidth: 0,
    plotShadow: false,
    backgroundColor: '#fcfcfd',
    marginTop: 35,
    spacing: [10, 0, 0, 0],
  },
  legend: {
    enabled: true,
    verticalAlign: 'top',
    align: 'left',
    padding: 0,
    symbolRadius: 0,
    margin: 0,
    itemDistance: 5,
    itemStyle: {
      fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
      fontSize: '10px',
      fontWeight: '500',
    },
  },
  plotOptions: {
    pie: {
      borderWidth: 0,
      borderRadius: 0,
    },
  },
  tooltip: {
    pointFormat: '<b>{point.y}</b>' + '<b>{point.count}</b><br/>',
  },
  series: [],
};

export const DONUT_TOP_LEGEND: Highcharts.LegendOptions = {
  verticalAlign: 'top',
  align: 'left',
  itemStyle: {
    fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
    fontSize: '12px',
    fontWeight: '500',
  },
  padding: 0,
  margin: 0,
  itemWidth: 100,
  itemMarginBottom: 3,
  itemMarginTop: 3,
  symbolHeight: 12,
  symbolWidth: 12,
  symbolPadding: 5,
  symbolRadius: 0,
};

export const DONUT_SERIES: Highcharts.SeriesPieOptions = {
  type: 'pie',
  innerSize: '50%',
  data: [],
  showInLegend: true,
  dataLabels: {
    style: {
      fontSize: '10',
    },
    enabled: true,
    format: '<b>{point.y}</b><br>{point.count}',
    distance: 10,
  },
};

export const FILEAPI = 'fileapi/v1';
