import { Component, OnDestroy, ViewEncapsulation, OnInit, ChangeDetectorRef, signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject, Subscription, of } from 'rxjs';
import { NavItem, isDefined } from '@slb-dls/angular-material/shared';
import { SlbNotificationItem } from '@slb-dls/angular-material/notifications-panel';
import { primaryNavItems } from './navigation-items';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { PopUpService } from './shared/services/pop-up.service';
import { LoginService } from '@agora/agora-ui-library';
import { TimeZoneService } from './shared/services/time-zone.service';
import { TimeZone } from './shared/models/dashabordAlertData';
import { CameraProfileService } from './shared/services/camera-profile.service';
import { ZoneConfig } from './shared/models/zoneSetting';
import { RequestId } from './shared/models/zone-configuration';
import { ZoneConfigurationConstants } from './shared/constants/zone-config.constant';
import { ZoneService } from './shared/services/zone.service';
import { UserSettingsService } from './shared/services/user-settings.service';
import { CUSTOM, METRIC } from './shared/constants/unit-constant';
import { UnitsData, UnitsGetData } from './shared/models/unit';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnDestroy, OnInit {
  public notificationCount = 10;
  public notificationItems: SlbNotificationItem[] = [];
  public showBackButton: boolean;
  public showHeader = false;
  public pageTitle = '';
  public primaryNavItems = JSON.parse(JSON.stringify(primaryNavItems));
  public options = ['EST', 'PST'];
  public cameraName: string;
  public refreshedDate = new Date();
  public currentTimezone = '';
  public refreshedTimezone = '';
  public userName!: string;
  public userEmail!: string;
  public currentZoneArea: string;
  public currentZoneTime: string;
  public country = 'All';
  public showCountry = false;
  public isCameraPage = false;
  public isCameraProfilePage: boolean;
  public disableZoneSetting: boolean;
  public cameraWorkflow: string;
  public panelOpenState: boolean;
  public authorized: boolean;
  public cameraStatus: string;
  public gateway: string;
  public controllerId: string;
  public rigName: string;
  public disableRequestImage = signal(false);
  public isZoneDefinationScreen: boolean;
  public isAllEvents: boolean;
  public isAdmin: boolean;
  private routerSubscription = Subscription.EMPTY;
  private timezoneSubscription = Subscription.EMPTY;
  private reLoadSubscription = Subscription.EMPTY;
  private zoneSettingSubscription = Subscription.EMPTY;
  private destroyApi = new Subject();
  constructor(
    private router: Router,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private popUpService: PopUpService,
    private messageService: MessageService,
    private loginService: LoginService,
    private timeZoneService: TimeZoneService,
    private cameraProfileService: CameraProfileService,
    private zoneService: ZoneService,
    private cd: ChangeDetectorRef,
    private userSettingService: UserSettingsService
  ) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-symbols.svg'));
  }

  ngOnInit(): void {
    this.panelOpenState = false;
    this.authorized = true;
    this.isAdmin = false;
    this.isAllEvents = false;
    this.showAllEvents();
    this.cameraProfileService.isAdmin$
      .pipe(
        tap(isAdminData => {
          this.isAdmin = isAdminData === 'true' ? true : false;
        })
      )
      .subscribe();
    this.disableRequestImage = this.zoneService.disableRequestImageButton;
    this.routerSubscription = this.router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        map(e => e as RoutesRecognized),
        tap(e => {
          this.onRouteChange(e);
        })
      )
      .subscribe();

    this.timezoneSubscription = this.timeZoneService.timeZoneDetails$
      .pipe(
        tap((timeZoneDetails: TimeZone) => {
          this.currentTimezone = timeZoneDetails.value;
          this.currentZoneArea = timeZoneDetails?.label;
          this.refreshedDate = this.timeZoneService.getDateandTime();
        })
      )
      .subscribe();

    this.loginService.isUserLoggedIn$
      .pipe(
        tap(() => {
          this.userEmail = this.loginService.getUserInfo()?.email ?? '';
          this.userName = this.loginService.getUserInfo()?.name ?? '';
        }),
        switchMap(() =>
          this.userSettingService.getLatestUnits(this.userEmail).pipe(
            tap((data: UnitsGetData) => {
              this.constructUnits(data);
            }),
            catchError(() => {
              const unit = this.userSettingService.createFinalUnits(METRIC, '', this.userEmail);
              unit.userEmail = this.userEmail;
              sessionStorage.setItem('unit', JSON.stringify(unit));
              this.userSettingService.setUnit(JSON.stringify(unit));

              return of<UnitsData>({} as UnitsData);
            })
          )
        )
      )
      .subscribe();

    this.zoneSettingSubscription = this.cameraProfileService.selectedZoneId$
      .pipe(
        tap((params: ZoneConfig) => {
          if (params) {
            this.disableZoneSetting = params.deviceId && params.groupdId ? false : true;
          } else {
            this.disableZoneSetting = true;
          }
        }),
        switchMap(() => this.cameraProfileService.cameraWorkflow$.pipe(tap((value: string) => (this.cameraWorkflow = value))))
      )
      .subscribe();

    this.chooseSecondaryNav();
  }

  public primaryNavigationSelected(event: NavItem): void {
    if (event) {
      this.primaryNavItems.forEach((navItem: NavItem) => (navItem.active = navItem.routerLink === event.routerLink ? true : false));
      if (event?.routerLink) {
        this.router.navigateByUrl(event?.routerLink as unknown as string);
      }
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.timezoneSubscription.unsubscribe();
    this.reLoadSubscription.unsubscribe();
    this.zoneSettingSubscription.unsubscribe();
  }

  public openZoneConfigurationPopUp(): void {
    this.router.navigate(['zoneDefinition', this.cameraName, this.cameraStatus, this.gateway, this.controllerId, this.rigName]);
  }

  public async openZoneSettingPopUp(): Promise<void> {
    const confirmation = await this.popUpService.openZoneSetting(this.cameraStatus);
    if (confirmation) {
      this.messageService.add({ severity: SlbSeverity.Success, summary: 'Success', detail: 'Zone setting saved successfully.' });
    }
  }

  public logout(): void {
    this.loginService.logout();
    sessionStorage.clear();
  }

  public navigateToSelf(): void {
    const actualRoute = this.router.url;
    let defaultRoute = '/';
    if (this.router.url === '/home') {
      defaultRoute = '/camera';
    }
    this.router.navigateByUrl(defaultRoute, { skipLocationChange: true }).then(() => {
      this.router.navigate([actualRoute]);
    });
  }

  public requestImage(): void {
    this.zoneService.showRequestImageLoader.set(true);
    this.zoneService.disableRequestImageButton.set(true);
    this.zoneService
      .getRequestImage(this.gateway, this.controllerId)
      .pipe(
        tap((data: RequestId) => {
          if (data && data?.mediaRequestId) {
            this.zoneService.setRequestImageData(data);
          }
        }),
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Info,
            summary: 'Error',
            detail: ZoneConfigurationConstants.ZONEGETIMAGEERROR,
          });
          this.zoneService.showRequestImageLoader.set(false);
          this.zoneService.disableRequestImageButton.set(false);

          return of<string>('');
        }),
        takeUntil(this.destroyApi)
      )
      .subscribe();
  }

  public showAllEvents(): void {
    const showEvents = this.isAllEvents ? 'All' : '';
    sessionStorage.setItem('showAllEvents', showEvents);
    this.cameraProfileService.getEventData();
  }

  public redirectUserSettings(): void {
    this.router.navigate(['userSettings']);
  }

  private chooseSecondaryNav(): void {
    const url = document.location.href;
    if (url && !url.includes('login') && url.split('/')?.[3] !== '' && this.primaryNavItems?.length) {
      this.primaryNavItems.forEach((navItem: NavItem) => {
        let checkUrl = url.split('/')?.[3];
        if (url?.split('/')?.[3] === 'zoneDefinition') {
          checkUrl = 'home';
        }
        navItem.active = checkUrl === navItem.routerLink ? true : false;
      });
    }
  }

  /* eslint-disable max-lines-per-function */
  private onRouteChange(event: RoutesRecognized): void {
    this.isZoneDefinationScreen = false;
    this.panelOpenState = false;
    this.refreshedDate = this.timeZoneService.getDateandTime();
    this.showCountry = false;
    this.isCameraPage = false;
    this.isCameraProfilePage = false;
    this.country = 'All';
    const data: any = event?.state?.root?.firstChild?.data;
    this.showHeader = isDefined(data.showHeader) ? data.showHeader : true;
    this.showBackButton = isDefined(data.showBackButton) ? data.showBackButton : false;
    let currentUrl = event.url;
    if (currentUrl?.includes('eventid')) {
      currentUrl = decodeURIComponent(currentUrl);
      sessionStorage.setItem('eventlink', currentUrl);
      sessionStorage.setItem('currentUrl', currentUrl);
    }
    if (!currentUrl?.includes('login') && !currentUrl?.includes('unauthorized')) {
      sessionStorage.setItem('currentUrl', currentUrl);
    }
    /** When unauthorized page comes, needs to hide the secondary navigation items  */
    if (currentUrl?.includes('unauthorized') || currentUrl?.includes('unsubscribed')) {
      this.authorized = false;
      this.primaryNavItems = [];
    } else {
      this.authorized = true;
      this.primaryNavItems = JSON.parse(JSON.stringify(primaryNavItems));
    }
    if (data?.title?.toLowerCase() === 'camera' || data?.title === 'Zone Definition') {
      this.isCameraProfilePage = data?.title?.toLowerCase() === 'camera' ? true : false;
      this.panelOpenState = data?.title?.toLowerCase() === 'camera' ? true : false;
      const paramData = event.state.root.firstChild?.params;
      if (paramData?.cameraName) {
        this.cameraName = paramData.cameraName;
      }
      if (paramData?.cameraStatus) {
        this.cameraStatus = paramData.cameraStatus;
      }
      if (paramData?.gateway) {
        this.gateway = paramData.gateway;
      }
      if (paramData?.controllerId) {
        this.controllerId = paramData.controllerId;
      }
      if (paramData?.rigName) {
        this.rigName = paramData.rigName;
      }
    } else {
      sessionStorage.removeItem('cameraName');
      this.cameraName = '';
      this.cameraWorkflow = '';
    }
    if (data?.title?.toLowerCase() === 'cameras') {
      this.isCameraPage = true;
      this.showCountry = true;
      const country = sessionStorage.getItem('country');
      if (country) {
        this.country = country === '' ? 'All' : country;
      }
      const item = this.primaryNavItems;
      this.primaryNavItems.forEach((navItem: NavItem) => (navItem.active = navItem.label?.toLowerCase() === 'camera'));
      this.primaryNavItems = [...item];
      this.cd.detectChanges();
    }
    this.pageTitle = data.title === 'Camera' ? this.cameraName : data.title;
    if (data?.title === 'Zone Definition') {
      this.isZoneDefinationScreen = true;
      const gatewayStatus = sessionStorage.getItem('gatewayStatus');
      this.zoneService.disableRequestImageButton.set(gatewayStatus !== '0' || this.cameraStatus !== '0' ? true : false);
      this.pageTitle = data.title + ' (' + this.cameraName + ')';
    }
  }

  private constructUnits(data: UnitsGetData): void {
    if (data?.SettingProperties) {
      let constructUnit = JSON.parse(JSON.stringify(data?.SettingProperties)) as UnitsData;
      constructUnit.timeStamp = data?.Created_at;
      if (constructUnit?.unit_name === CUSTOM) {
        constructUnit = this.userSettingService.createCustomUnits(constructUnit?.unit_data, constructUnit.timeStamp, this.userEmail);
      } else {
        constructUnit = this.userSettingService.createFinalUnits(constructUnit?.unit_name, constructUnit.timeStamp, this.userEmail);
      }
      this.userSettingService.setUnit(JSON.stringify(constructUnit));
    }
  }
}
