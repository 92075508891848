<div class="zone-defination-container">
  <div class="image-container">
    <div class="image-video-preview" *ngIf="previewError === ''; else imageVideoPreview">
      <app-spinner class="image-loader" *ngIf="imageLoader()"></app-spinner>
      <canvas #canvas [width]="deviceWidth" [height]="deviceHeight" [ngClass]="imageLoader() ? 'hidden' : 'visible'"></canvas>
    </div>
    <ng-template #imageVideoPreview>
      <div class="error-preview" *ngIf="!imageLoader(); else loader">
        <div>
          <p class="error-icon"><img src="assets/icons/icon camera-graphic.png" /></p>
          <p class="preview-title">Definition zone</p>
          <p class="preview-error">{{ previewError }}</p>
        </div>
      </div>
      <ng-template #loader>
        <app-spinner class="image-loader"></app-spinner>
      </ng-template>
    </ng-template>
  </div>
  <div class="metadata-container" *ngIf="!zoneConfigLoader; else configLoader">
    <div class="meta-content" *ngIf="previewError === '' && zoneConfiguration?.zoneConfigFileUrl !== ''">
      <mat-form-field id="vi_zone_defination_workflow-field" class="workflow-field">
        <mat-label> Workflow </mat-label>
        <mat-select [(ngModel)]="workflow" (selectionChange)="changeWorkflow()" [disabled]="true">
          <mat-option value="PPE"> PPE zone </mat-option>
          <mat-option value="RedZone"> Red zone </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-button
        slb-button
        [disabled]="!hasZone || deviceOffline"
        (click)="removeRedzone()"
        class="remove-zone"
        id="vi_zone_defination_remove_zone_button">
        <mat-icon svgIcon="delete" class="delete-icon"></mat-icon>
        <span class="remove-btn-label"> Remove current zone</span>
      </button>
      <mat-form-field class="zone-name-field" slbFormField id="vi_zone_defination_zone_name" class="workflow-field">
        <mat-label> Zone name </mat-label>
        <input
          matInput
          pattern="^[a-zA-Z0-9\-\s]*$"
          required
          name="zoneNameField"
          #zoneNameField="ngModel"
          [(ngModel)]="zoneName"
          maxlength="25"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="!hasZone || deviceOffline"
          (ngModelChange)="checkZoneNameValid()" />
        <mat-hint
          *ngIf="
            (!zoneName || (zoneName && !zoneName.trim().length) || zoneNameField.errors || !isZoneNameValid) &&
            (zoneNameField.dirty || zoneNameField.touched)
          ">
          <span class="error-msg"> Please enter a valid zone name. </span>
        </mat-hint>
      </mat-form-field>
      <span class="description">
        <p>To create a zone, click on the desired area of the image and name the zone before saving.</p>
      </span>
      <!-- <div class="ppe-events" *ngIf="workflow === 'PPE'">
        <p class="title">Enable feature for this camera</p>
        <div class="event-container">
          <div class="event-item" *ngFor="let event of ppeEventsOptions">
            <mat-checkbox class="checkbox" [(ngModel)]="event.isChecked" class="zone-event-checkbox">
              <p>
                <span><img src="{{ event.img }}" class="event-icon" /></span><span class="event-title">{{ event.label }}</span>
              </p>
            </mat-checkbox>
          </div>
        </div>
      </div> -->
      <div class="zone-metadata" *ngIf="!noZoneConfiguration && zoneConfiguration">
        <p>
          Zone: <span>{{ zoneConfiguration.zoneName }}</span>
        </p>
        <p>
          Last updated at: <span>{{ zoneConfiguration.timestamp | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}</span>
        </p>
        <p>
          Updated by: <span>{{ zoneConfiguration.UpdatedBy }}</span>
        </p>
      </div>
    </div>
    <div class="footer">
      <button
        id="zone_configuration_dialog_close"
        class="cancel-btn"
        mat-button
        slb-secondary-button
        type="button"
        (click)="navigateToCameraScreen()"
        id="vi_zone_defination_cancel_button">
        Cancel
      </button>
      <button
        id="zone_configuration_dialog_submit"
        class="save-btn"
        mat-button
        slb-button
        type="submit"
        cdkFocusInitial
        (click)="saveZoneConfiguration()"
        [disabled]="deviceOffline || !isZoneChange || !zoneName.trim().length || !isZoneNameValid || zoneConfigLoader || !hasZone"
        id="vi_zone_defination_save_button">
        Save
      </button>
    </div>
  </div>
  <ng-template #configLoader>
    <div class="config-loader-preview">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
<slb-toast position="top-right" *ngIf="!showConfirmation"> </slb-toast>
