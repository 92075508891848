import { NavItem } from '@slb-dls/angular-material/shared';

export const primaryNavItems: NavItem[] = [
  {
    label: 'Dashboard',
    routerLink: 'home',
    active: true,
    icon: 'home',
  },
  {
    label: 'Camera',
    routerLink: 'camera',
    active: false,
    icon: 'survey',
  },
];
