import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CUSTOM, UNITS_TABLE_DATA, UNITCONSTANT, UNITVALUECONSTANT } from '../constants/unit-constant';
import { Units, UnitsData } from '../models/unit';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiConstants } from '../constants/api-constant';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  public unitSelected$: Observable<string>;
  public unitSelected = new BehaviorSubject<string | ''>(sessionStorage.getItem('unit') ?? '');
  private baseUrl: string;
  private url = ApiConstants.BASEURL;

  constructor(private http: HttpClient) {
    this.unitSelected$ = this.unitSelected.asObservable();
    this.baseUrl = environment.apiBaseUrl;
  }

  public getLatestUnits(userEmail: string): Observable<any> {
    return this.http.get(`${this.baseUrl}${ApiConstants.BASEURL}user-settings/` + userEmail + `/unit/latest`);
  }

  public saveLatestUnits(unit: UnitsData): Observable<any> {
    const constructUnit = {
      unit_name: unit.unit_name,
      unit_data: unit.unit_data,
    };
    const data = {
      referenceType: 'user',
      referenceID: unit.userEmail,
      preference: 1,
      settingType: 'unit',
      settingProperties: JSON.stringify(constructUnit),
      created_at: new Date().toISOString(),
      created_by: unit.userEmail,
    };

    return this.http.post(`${this.baseUrl}${this.url}add-user-settings`, data);
  }

  public setUnit(unitData: string): void {
    this.unitSelected.next(unitData);
  }

  public createFinalUnits(selectedColumn: string, timeStamp: string, userEmail: string): UnitsData {
    const units = JSON.parse(JSON.stringify(UNITS_TABLE_DATA));
    const finalArray: { [key: string]: string }[] = units.map((tableData: Units) => {
      return {
        [tableData.unitName]: tableData[selectedColumn],
      };
    });

    const unit: UnitsData = {
      unit_name: selectedColumn,
      unit_data: finalArray,
      timeStamp,
      userEmail,
    };

    return unit;
  }

  public createCustomUnits(units: { [key: string]: string }[], timeStamp: string, userEmail: string): UnitsData {
    const unit: UnitsData = {
      unit_name: CUSTOM,
      unit_data: units,
      timeStamp,
      userEmail,
    };

    return unit;
  }

  public siToChosenUnit(value: number, fromUnit: string, toUnit: string): string {
    let calculatedValue = '';
    fromUnit = fromUnit.replace(/\(([^)]+)\)/g, '$1');
    toUnit = toUnit.replace(/\(([^)]+)\)/g, '$1');
    if (fromUnit === UNITCONSTANT.METER && toUnit === UNITCONSTANT.FEET) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.METERTOFEET);
    } else if (fromUnit === UNITCONSTANT.MERTERPERSEC && toUnit === UNITCONSTANT.FEETPERSEC) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.METERTOFEET);
    } else if (fromUnit === UNITCONSTANT.PASCAL && toUnit === UNITCONSTANT.KILOPASCAL) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.UNITTOKILO);
    } else if (fromUnit === UNITCONSTANT.PASCAL && toUnit === UNITCONSTANT.PSI) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.PASCALTOPSI);
    } else if (fromUnit === UNITCONSTANT.METERCUBEPERSEC && toUnit === UNITCONSTANT.LITREPERMIN) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.M3PERSECTOLPERMIN);
    } else if (fromUnit === UNITCONSTANT.METERCUBEPERSEC && toUnit === UNITCONSTANT.GALOONSPERMIN) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.M3PERSECTOGPERMIN);
    } else if (fromUnit === UNITCONSTANT.NEWTON && toUnit === UNITCONSTANT.MILLIGRAVEFORCE) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.NEWTOMGF);
    } else if (fromUnit === UNITCONSTANT.NEWTON && toUnit === UNITCONSTANT.KIPFORCE) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.NEWTOKLPF);
    } else if (fromUnit === UNITCONSTANT.NEWTONMETER && toUnit === UNITCONSTANT.KILONEWTONMETER) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.UNITTOKILO);
    } else if (fromUnit === UNITCONSTANT.NEWTONMETER && toUnit === UNITCONSTANT.KIPFORCEPERFEET) {
      calculatedValue = this.checkDecimal(value * UNITVALUECONSTANT.NMTOKLPFFT);
    } else {
      calculatedValue = this.checkDecimal(value);
    }

    return calculatedValue;
  }

  public conversionToSIUnits(value: number, unit: string): number {
    unit = unit.replace(/\(([^)]+)\)/g, '$1');
    let calculatedValue = 0;
    switch (unit) {
      case UNITCONSTANT.FEET:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.METERTOFEET));
        break;
      case UNITCONSTANT.FEETPERSEC:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.METERTOFEET));
        break;
      case UNITCONSTANT.PSI:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.PASCALTOPSI));
        break;
      case UNITCONSTANT.KILOPASCAL:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.UNITTOKILO));
        break;
      case UNITCONSTANT.LITREPERMIN:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.M3PERSECTOLPERMIN));
        break;
      case UNITCONSTANT.GALOONSPERMIN:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.M3PERSECTOGPERMIN));
        break;
      case UNITCONSTANT.MILLIGRAVEFORCE:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.NEWTOMGF));
        break;
      case UNITCONSTANT.KIPFORCE:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.NEWTOKLPF));
        break;
      case UNITCONSTANT.KILONEWTONMETER:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.UNITTOKILO));
        break;
      case UNITCONSTANT.KIPFORCEPERFEET:
        calculatedValue = Number(this.checkDecimal(value / UNITVALUECONSTANT.NMTOKLPFFT));
        break;
      default:
        calculatedValue = Number(this.checkDecimal(value));
        break;
    }

    return calculatedValue;
  }

  public getUnit(si: string, unitSelected: { [key: string]: string }[]): string {
    let chosenUnit: string = si;
    unitSelected.forEach(unit => {
      const unitName = Object.keys(unit)[0];
      const unitValue = unit[unitName];
      const validUnit = si.replace(/\(([^)]+)\)/g, '$1');
      const formData = UNITS_TABLE_DATA.find(d => d.unitName === unitName && d.si === validUnit);
      if (formData) {
        chosenUnit = '(' + unitValue + ')';
      }
    });

    return chosenUnit;
  }

  private checkDecimal(value: number): string {
    const splitDecimal = value.toString()?.split('.');
    let strValue = value.toString();
    if (splitDecimal?.[1]?.length > 7) {
      strValue = value.toFixed(7);
    } else if (splitDecimal?.[1]?.length > 0) {
      strValue = value.toFixed(splitDecimal?.[1]?.length);
    } else {
      strValue = value.toFixed(1);
    }

    return strValue;
  }
}
