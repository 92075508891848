export enum Workflows {
  RedZone,
  PPE,
}

export enum GatewayStatus {
  Active,
  Inactive,
  Disconnected,
}

export enum RigState {
  Wits,
  Gn5,
}

export enum DynamicRedZone {
  NA,
  BlockMovingUp,
  BlockMovingDown,
  MudCirculation,
  DrillingRotation,
  RedZoneOn,
}

export enum MachineMovementCompliance {
  MudCirculation,
  BlockMovingDown,
  BlockMovingUp,
  None,
}

export enum RigStateCameraAlertList {
  MudCirculation,
  BlockMovingDown,
  BlockMovingUp,
  None,
}

export enum CameraStatus {
  Active,
  Inactive,
  Disconnected,
}

export enum RigDataStatus {
  Active,
  Disconnected,
}

export enum RealTimeRigDataStatus {
  Active,
  Inactive,
  Disconnected,
}

export enum Flag {
  NeverMiss,
  Minorincident,
  Losttimeinjuries,
  Fatality,
}

export enum Category {
  Yettobereviewed,
  ReviewedInvalid,
  ReviewedasSystemdetected,
  ReviewedHumanBehavior,
  Exclude,
}
