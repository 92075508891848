<div class="camera-list-container">
  <div class="date-fields">
    <mat-form-field class="gateway-dropdown" appearance="outline" floatLabel="always">
      <mat-label>Gateway</mat-label>
      <mat-select
        hideSingleSelectionIndicator="true"
        #gatewaySelect="matSelect"
        [(ngModel)]="selectedGateway"
        (selectionChange)="changeGateway($event.value)"
        (openedChange)="isSelectBoxOpened($event)"
        [id]="id + '_select_gateway'">
        <input
          matInput
          class="filterSelect"
          type="text"
          (keydown)="$event.stopPropagation(); filterGatewayList($event)"
          [(ngModel)]="filteredGatewayName" />
        <mat-divider></mat-divider>
        <mat-option value="All">All</mat-option>
        <mat-option *ngFor="let gateway of gatewayList?.gateways" [value]="gateway.gatewayId">{{ gateway.gatewayName }} </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="camera-dropdown" appearance="outline" floatLabel="always">
      <mat-label>Camera name</mat-label>
      <mat-select
        hideSingleSelectionIndicator="true"
        [(ngModel)]="camera"
        #cameraSelect="matSelect"
        (selectionChange)="changeCamera($event.value)"
        (openedChange)="isSelectBoxOpened($event)"
        [id]="id + '_select_camera'">
        <input
          matInput
          type="text"
          class="filterSelect"
          (keydown)="$event.stopPropagation(); filterCameraList($event)"
          [(ngModel)]="filteredCameraName" />
        <mat-divider></mat-divider>
        <mat-option value="All">All Camera</mat-option>
        <mat-option *ngFor="let camera of cameraSelectionList" [value]="camera.controllerId">
          {{ camera.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="camera-table-container">
    <div class="table-header">
      <h3 class="table-title">Camera list</h3>
      <button
        slb-button
        class="download-btn"
        (click)="downloadCameraDetails()"
        [disabled]="disableDownload"
        [id]="id + '_download_camera_list'">
        Download CSV
      </button>
    </div>
    <div class="table-container" *ngIf="!isLoading; else loader">
      <table mat-table [dataSource]="dataSource" *ngIf="cameraList.length; else no_data" class="table">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'camera_list' + '_row_selection_' + (i + 1) }}">
            <mat-checkbox
              (click)="rowSelection()"
              (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="gatewayName">
          <th mat-header-cell *matHeaderCellDef>Gateway</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ 'camera_list' + '_gateway_name_' + (i + 1) }}"
            (click)="getRecord(element)"
            class="gateway">
            <mat-icon
              svgIcon="oil-bitumen"
              [ngClass]="{
                active: element.gatewayStatus === 0,
                inactive: element.gatewayStatus === 1,
                disconnected: element.gatewayStatus === 2
              }"></mat-icon>
            {{ element.gatewayName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="cameraName">
          <th mat-header-cell *matHeaderCellDef>Camera</th>
          <td
            mat-cell
            class="camera"
            (click)="getRecord(element)"
            *matCellDef="let element; let i = index"
            id="{{ 'camera_list' + '_camera_name_' + (i + 1) }}">
            <mat-icon
              svgIcon="oil-bitumen"
              [ngClass]="{
                active: element.cameraStatus === 0,
                inactive: element.cameraStatus === 1,
                disconnected: element.cameraStatus === 2
              }"></mat-icon>
            {{ element.cameraName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="rigName">
          <th mat-header-cell *matHeaderCellDef>Site</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ 'camera_list' + '_rig_name_' + (i + 1) }}"
            (click)="getRecord(element)">
            {{ element.rigName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="redZoneName">
          <th mat-header-cell *matHeaderCellDef>Red zone name</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ 'camera_list' + '_red_zone_name_' + (i + 1) }}"
            (click)="getRecord(element)">
            {{ element.redZoneName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ppeZoneName">
          <th mat-header-cell *matHeaderCellDef>PPE zone name</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ 'camera_list' + '_ppe_zone_name_' + (i + 1) }}"
            (click)="getRecord(element)">
            {{ element.ppeZoneName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastUpdatedStatus">
          <th mat-header-cell *matHeaderCellDef>Last updated camera status</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ 'camera_list' + '_last_updated_status' + (i + 1) }}"
            (click)="getRecord(element)">
            {{ element.lastUpdatedStatus | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}
          </td>
        </ng-container>
        <ng-container matColumnDef="realTimeRigDataStatus">
          <th mat-header-cell *matHeaderCellDef>Real time rig data status</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ 'camera_list' + '_realtime_rig_data_status' + (i + 1) }}"
            (click)="getRecord(element)">
            <mat-icon svgIcon="oil-bitumen" [ngClass]="element.realTimeRigDataStatus === 1 ? 'active' : 'disconnected'"></mat-icon>
            {{ element.realTimeRigDataStatus === 1 ? 'Active' : 'Disconnected' }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="clickable"></tr>
      </table>
    </div>

    <mat-paginator
      *ngIf="cameraList?.length"
      [id]="id + '_table_pagination'"
      [pageSizeOptions]="[10, 20, 30]"
      showLabel="false"
      [showFirstLastButtons]="true"
      (page)="onPageFired($event)"
      [length]="totalRows">
    </mat-paginator>
    <ng-template #no_data>
      <p class="align-center" id="vi_camera_list_no_data">No data available.</p>
    </ng-template>
  </div>
</div>
<ng-template #loader>
  <div class="spinner">
    <app-spinner></app-spinner>
  </div>
</ng-template>
<slb-toast> </slb-toast>
