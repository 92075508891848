import { Component, OnDestroy, OnInit } from '@angular/core';
import { Chart } from '../../shared/models/chart';
import { Subject, catchError, of, switchMap, takeUntil, tap } from 'rxjs';
import { EventDetails } from '../../shared/models/eventDetails';
import { CameraProfileDetails } from '../../shared/models/cameraDetails';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import {
  CAMERAPROFILEERROR,
  HIGHCHARTCHARTDATA,
  EVENTCOLORS,
  EVENT_STATUS_CHART_OPTION,
  ALL,
  EXCLUDED,
} from '../../shared/constants/camera-profile-constant';
import { ActivatedRoute } from '@angular/router';
import { ZoneConfig } from '../../shared/models/zoneSetting';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import { CHARTCOLORS, LINECHARTOPTIONS } from '../../shared/constants/chart-constant';
import { EventChart } from '../../shared/models/eventChart';
import { DashboardConstants } from '../../../app/shared/constants/dashboard-constants';

noData(Highcharts);

@Component({
  selector: 'app-camera-profile-dashboard',
  templateUrl: './camera-profile-dashboard.component.html',
  styleUrls: ['./camera-profile-dashboard.component.scss'],
})
export class CameraProfileDashboardComponent implements OnInit, OnDestroy {
  public riskChartData: Chart;
  public violationChartData: Chart;
  public violationData: EventDetails[];
  public cameraProfileData: CameraProfileDetails;
  public aspectRatio = 3;
  public isLoading: boolean;
  public rigName: string;
  public sessionToken: string;
  public Highcharts = Highcharts;
  public eventOptions: Highcharts.Options;
  public violationOptions: Highcharts.Options;
  public redZoneType: number;
  public updateViolationChart: boolean;
  public updateRiskChart: boolean;
  public eventLineChartData: Chart;
  public eventLineOptions: Highcharts.Options;
  public noOfEvents: number;
  public updateEventLineChart: boolean;
  public reviewLoader: boolean;
  private gatewayId: string;
  private controllerId: string;
  private destroyed = new Subject();
  private countryNameSession: string;
  private timeZone: string;
  private reviewCategory: string;
  private showAllEvents: string;

  constructor(private cameraProfileService: CameraProfileService, private messageService: MessageService, private route: ActivatedRoute) {
    this.route.params
      .pipe(
        tap(params => {
          if (params) {
            this.gatewayId = params.gateway;
            this.controllerId = params.controllerId;
            this.rigName = params.rigName;
            if (params.rigName.includes('*')) {
              this.rigName = params.rigName.split('*').join(' ');
            }
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    this.updateViolationChart = false;
    this.updateRiskChart = false;
    this.updateEventLineChart = false;
    this.reviewCategory = ALL;
    this.countryNameSession = sessionStorage.getItem('country') ?? '';
    this.timeZone = sessionStorage.getItem('timezone') ?? '';
    this.getData();
  }

  public setReviewCategory(event: any): void {
    this.reviewLoader = true;
    this.reviewCategory = event?.toLowerCase();
    if (this.reviewCategory !== EXCLUDED) {
      this.reviewCategory = ALL;
    }
    this.getData();
  }

  ngOnDestroy(): void {
    this.cameraProfileService.setZoneId({} as ZoneConfig);
    this.cameraProfileService.cameraWorkflow.set('');
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private formatLabel(key: string): string {
    key.replace(key[0], key[0].toUpperCase());
    const splitBasedonUpperCase = key.replace(key[0], key[0].toUpperCase()).match(/[A-Z][a-z]+/g);
    if (splitBasedonUpperCase?.length) {
      return splitBasedonUpperCase.join(' ');
    }

    return '';
  }

  private getData(): void {
    let startDate = new Date();
    let endDate = new Date();
    this.cameraProfileService.eventData$
      .pipe(
        tap(_value => {
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 6);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          this.initializeData();
        }),
        switchMap(() =>
          this.cameraProfileService
            .getCameraDetails(
              this.gatewayId,
              this.controllerId,
              this.reviewCategory,
              startDate.toISOString(),
              endDate.toISOString(),
              this.showAllEvents
            )
            .pipe(
              tap(profileData => {
                if (profileData) {
                  this.setProfileFlow(profileData);
                }
              }),
              catchError(() => {
                this.messageService.add({ severity: SlbSeverity.Error, summary: CAMERAPROFILEERROR });
                this.isLoading = false;
                this.reviewLoader = false;

                return of<string>('');
              })
            )
        ),
        switchMap(() =>
          this.cameraProfileService
            .getEventTrendDetails(
              this.gatewayId,
              this.controllerId,
              startDate.toISOString(),
              endDate.toISOString(),
              this.countryNameSession,
              this.reviewCategory,
              this.timeZone,
              this.showAllEvents
            )
            .pipe(
              tap((eventLineChartData: EventChart) => {
                if (eventLineChartData) {
                  this.createEventLineChart(eventLineChartData);
                }
                this.isLoading = false;
                this.reviewLoader = false;
              }),
              catchError(() => {
                this.messageService.add({ severity: SlbSeverity.Error, summary: CAMERAPROFILEERROR });
                this.isLoading = false;
                this.reviewLoader = false;

                return of<string>('');
              })
            )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private initializeData(): void {
    this.showAllEvents = sessionStorage.getItem('showAllEvents') || '';
    this.reviewLoader = true;
    this.eventOptions = JSON.parse(JSON.stringify(HIGHCHARTCHARTDATA));
    this.violationOptions = JSON.parse(JSON.stringify(EVENT_STATUS_CHART_OPTION));
    this.eventLineOptions = JSON.parse(JSON.stringify(LINECHARTOPTIONS));
  }

  private createEventChart(chartData: { [keys: string]: number }): void {
    this.eventOptions.series = [];
    let options = chartData.total ? this.createDonutChartDataOption(chartData) : [];
    options = options.sort((a, b) => (a.y && b.y ? b.y - a.y : 0));
    if (options.length > 0) {
      this.eventOptions.series?.push({
        type: 'pie',
        innerSize: '50%',
        data: options,
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      } as Highcharts.SeriesPieOptions);
    }
    this.updateRiskChart = true;
  }

  private createEventLineChart(chartData: EventChart): void {
    const eventSeries: Highcharts.SeriesOptionsType[] = [];
    let noOfEvents = 0;
    DashboardConstants.EVENTTYPE.map(type => {
      const seriesData: Highcharts.PointOptionsObject[] = [];
      chartData?.event?.map(e => {
        const eventByType = e.eventTotal.find(ev => this.checkType(type, ev.eventType));
        if (eventByType) {
          seriesData.push({
            y: eventByType.total,
            x: new Date(e.date + ' 00:00').getTime(),
          } as Highcharts.PointOptionsObject);
          noOfEvents += eventByType.total;
        }
      });
      if (seriesData.length > 0) {
        eventSeries.push({
          lineWidth: 2,
          type: 'line',
          name: type,
          color: CHARTCOLORS.filter(tag => this.checkType(type, tag.name))[0]
            ? CHARTCOLORS.filter(tag => this.checkType(type, tag.name))[0].color
            : '',
          data: seriesData,
          visible: true,
        } as Highcharts.SeriesOptionsType);
      }
    });
    this.eventLineOptions.series = eventSeries;
    this.noOfEvents = noOfEvents;
    this.updateEventLineChart = true;
  }

  private createViolationChart(chartData: { [keys: string]: number }): void {
    this.violationOptions.series = [];
    const options = chartData.total ? this.createDonutChartDataOption(chartData) : [];
    if (options.length > 0) {
      this.violationOptions.series?.push({
        type: 'pie',
        innerSize: '50%',
        data: options,
        showInLegend: true,
        dataLabels: {
          enabled: true,
          distance: 20,
          format: '<b>{point.y}</b><br>{point.count}',
        },
      } as Highcharts.SeriesPieOptions);
    }
    this.updateViolationChart = true;
  }

  private setProfileFlow(profileData: CameraProfileDetails): void {
    this.cameraProfileData = profileData;
    this.cameraProfileService.setProfileData(profileData.cameraDetails);
    this.createViolationChart(this.cameraProfileData.violations);
    this.createEventChart(this.cameraProfileData.events);
    this.cameraProfileService.setZoneId({
      deviceId: this.cameraProfileData.cameraDetails.controllerId,
      groupdId: this.cameraProfileData.cameraDetails.groupID,
      zoneId: this.cameraProfileData.cameraDetails.zoneSettingsID ? this.cameraProfileData.cameraDetails.zoneSettingsID : 0,
      redZoneType: this.cameraProfileData.cameraDetails.smartRedZone,
    } as ZoneConfig);
    this.cameraProfileService.cameraWorkflow.set(profileData.cameraDetails.workflow);
  }

  private createDonutChartDataOption(chartData: { [keys: string]: number }): Highcharts.PointOptionsObject[] {
    this.eventOptions.series = [];
    const options: Highcharts.PointOptionsObject[] = [];
    Object.keys(chartData).map((key: string) => {
      if (key !== 'total' && chartData[key] !== 0) {
        const label = this.formatLabel(key);
        const color = EVENTCOLORS.filter(tag => tag.name === label)[0] ? EVENTCOLORS.filter(tag => tag.name === label)[0].color : '';
        options.push({
          name: label,
          y: chartData[key],
          color,
          fillColor: color,
        });
      }
    });

    return options;
  }

  private checkType(type: string, eventType: string): boolean {
    return type.split(' ').join('').toLowerCase().includes(eventType.toLowerCase());
  }
}
