<div class="zone-setting-container">
  <div class="zone-setting-title">
    <span mat-dialog-title class="zone-setting-dialog-title">Zone setting</span>
    <slb-modal-close-button id="zone-setting_close_btn" buttonType="Icon" [dialogRef]="dialogRef"></slb-modal-close-button>
  </div>
  <div mat-dialog-content class="zone-setting-dialog-content">
    <span *ngIf="inactiveGateway" class="disable-msg">Gateway is disconnected.</span>
    <span *ngIf="inactiveCamera" class="disable-msg">Camera is disconnected.</span>
    <span class="disable-msg" *ngIf="status === 'Error' && !showLoader && !inactiveCamera && !inactiveGateway"
      >Configuration deployment failed.</span
    >
    <div class="zone-setting-red-zone">
      <slb-radio-button-group
        (valueChanged)="isValueChange = true"
        [disabled]="inactiveGateway || inactiveCamera"
        [isVertical]="false"
        label="Red zone: "
        [(ngModel)]="currentRedZone"
        [values]="redZoneTypes"></slb-radio-button-group>
    </div>
    <div class="zone-settings-configuration" *ngIf="!showLoader; else loader">
      <div class="zone-setting-grid-row" *ngFor="let zone of zoneSettings; let i = index">
        <p class="zone-tag">
          {{ zone.label }} {{ zone.unit }}
          <span><mat-icon *ngIf="zone.info !== ''" svgIcon="info" class="info-icon" matTooltip="{{ zone.info }}"></mat-icon></span>
        </p>
        <mat-form-field slbFormField>
          <input
            matInput
            name="zoneValueField"
            type="number"
            pattern="^-?\d*[.,]?\d{0,7}$"
            required
            [(ngModel)]="zone.zoneUpdatedValue"
            #zoneValueField="ngModel"
            (ngModelChange)="checkZoneSettingValid($event, i)"
            [disabled]="!(currentRedZone === 2) || inactiveGateway || inactiveCamera" />
          <mat-hint *ngIf="zoneValueField.errors && (zoneValueField.dirty || zoneValueField.touched)">
            <span class="error"> Invalid input </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-dialog-actions>
    <div class="updated-info">
      <span class="updated-label">Updated By:</span>
      <span class="updated-value">{{ updatedBy }}</span>
      <span class="updated-pipe">|</span>
      <span class="updated-label">Updated on:</span>
      <span class="updated-value">{{ updatedOn | date : 'MM/dd/yyyy' }}</span>
    </div>
    <div class="actions">
      <button id="zone_setting_dialog_close" mat-button slb-secondary-button="button" (click)="close()">Close</button>
      <button
        id="zone_setting_dialog_submit"
        mat-button
        slb-button
        type="submit"
        cdkFocusInitial
        (click)="saveZoneSettings()"
        [disabled]="!allValid(isValid) || !isValueChange || inactiveGateway || inactiveCamera">
        Save
      </button>
    </div>
    <ng-template #loader>
      <div class="spinner">
        <mat-spinner *ngIf="showLoader" [diameter]="40"></mat-spinner>
      </div>
    </ng-template>
  </mat-dialog-actions>
</div>
