import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { UserSettingsService } from '../../../app/shared/services/user-settings.service';
import { CUSTOM, METRIC, UNITS_TABLE_DATA, UNIT_DISPLAYED_COLUMNS, UNIT_SAVE_SUCCESS } from '../../shared/constants/unit-constant';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Units, UnitsData } from '../../../app/shared/models/unit';
import { Subject, catchError, of, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss'],
})
export class UnitsComponent implements OnInit, AfterViewInit, OnDestroy {
  public dataSource: MatTableDataSource<any, MatTableDataSourcePaginator>;
  public displayedColumns: string[] = UNIT_DISPLAYED_COLUMNS;
  public data = UNITS_TABLE_DATA;
  public units: UnitsData;
  public selectedColumnName: string;
  public customFormGroup: FormGroup;
  public error: boolean;
  public isNotCustom: boolean;
  public disableSave: boolean;
  public timeStamp: Date;
  public loader: boolean;
  private userEmail: string;
  private destroyed = new Subject();

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private userSettingService: UserSettingsService,
    private messageService: MessageService
  ) {
    this.customFormGroup = this.fb.group({});
    this.data.forEach((form: Units) => {
      let value = '';
      if (form.custom.length === 1) {
        value = form.custom[0];
      }
      this.customFormGroup.addControl(form.formName, new FormControl({ value, disabled: true }, Validators.required));
    });
  }

  ngOnInit(): void {
    this.error = false;
    this.isNotCustom = false;
    this.disableSave = false;
    this.loader = true;
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(): void {
    this.userSettingService.unitSelected$
      .pipe(
        tap((units: string) => {
          if (units) {
            this.disableSave = true;
            this.units = JSON.parse(units) as UnitsData;
            if (this.units?.timeStamp) {
              this.timeStamp = new Date(this.units?.timeStamp);
            }
            if (this.units?.userEmail) {
              this.userEmail = this.units.userEmail;
            }
            this.selectedColumnName = this.units?.unit_name;
            this.highlight(this.units?.unit_name, false);
            if (this.selectedColumnName === CUSTOM) {
              this.populateCustom();
            }
          } else {
            this.selectedColumnName = METRIC;
          }
          this.loader = false;
          this.cd.detectChanges();
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public highlight(columnSelected: string, isChange: boolean): void {
    this.selectedColumnName = columnSelected;
    this.isNotCustom = this.selectedColumnName !== CUSTOM;
    if (isChange) {
      this.disableSave = false;
    }
    if (!this.isNotCustom) {
      this.customFormGroup.enable();
      this.checkCustomValid();
    } else {
      this.customFormGroup.disable();
    }
    this.cd.detectChanges();
  }

  public saveUnits(): void {
    const createdData = this.createUnit();
    this.loader = true;
    this.disableSave = true;
    if (!this.error && createdData) {
      this.userSettingService
        .saveLatestUnits(createdData)
        .pipe(
          tap(() => {
            sessionStorage.setItem('unit', JSON.stringify(createdData));
            this.userSettingService.setUnit(JSON.stringify(createdData));
            this.messageService.add({
              severity: SlbSeverity.Success,
              summary: 'Success',
              detail: this.selectedColumnName.charAt(0).toUpperCase() + this.selectedColumnName.slice(1) + ' ' + UNIT_SAVE_SUCCESS,
            });
            this.loader = false;
          }),
          catchError(() => {
            this.messageService.add({
              severity: SlbSeverity.Error,
              summary: 'Error',
              detail:
                'Due to technical difficulties we are unable to apply ' +
                this.selectedColumnName +
                ' unit settings. Please try again later.',
            });
            this.loader = false;
            this.disableSave = false;

            return of<UnitsData>({} as UnitsData);
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
  }

  public checkCustomValid(): void {
    const formValue: { [key: string]: string } = this.customFormGroup.value;
    const getFilteredValue: string[] = Object.keys(formValue).filter((data: string) => formValue[data] !== '') as string[];
    if (getFilteredValue.length !== this.data.length) {
      this.disableSave = true;
    } else {
      this.disableSave = false;
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  private populateCustom(): void {
    if (this.selectedColumnName === CUSTOM) {
      const unitData = this.units.unit_data;
      unitData.forEach(unit => {
        const unitName = Object.keys(unit)[0];
        const unitValues = unit[unitName];

        const formData = this.data.find(d => d.unitName === unitName);
        if (formData) {
          this.customFormGroup.controls[formData.formName].setValue(unitValues);
        }
      });
    }
  }

  private createUnit(): UnitsData | null {
    this.timeStamp = new Date();
    if (this.selectedColumnName === CUSTOM) {
      const createCustomeArr: { [key: string]: string }[] = [];
      const formValue: { [key: string]: string } = this.customFormGroup.value;
      const getValues = Object.values(formValue) as string[];
      const getKeys = Object.keys(formValue) as string[];
      const getNotNullValues = getValues.filter((data: string) => data !== '') as string[];
      this.error = getNotNullValues.length !== this.data.length;
      if (!this.error) {
        getKeys.forEach((key: string) => {
          this.data.forEach((data: Units) => {
            if (key === data.formName) {
              createCustomeArr.push({ [data.unitName]: formValue[key] });
            }
          });
        });

        return this.userSettingService.createCustomUnits(createCustomeArr, new Date().toISOString(), this.userEmail);
      }
    } else if (this.selectedColumnName !== CUSTOM) {
      return this.userSettingService.createFinalUnits(this.selectedColumnName, new Date().toISOString(), this.userEmail);
    }

    return null;
  }
}
