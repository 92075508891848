export class EventReviewConstants {
  public static FlagsData = ['Valid', 'Blurred camera' , 'Missing People Bounding Boxes', 'Duplicated People Bounding Boxes',
    'Invalid - PPE False Positive', 'Invalid - PPE Wrong Person',  'Invalid - Ghost Person', 'Invalid - Delayed Rig Data',
   'Invalid - Person not in Zone'];
  public static Categories = [
    'Yet to be reviewed',
    'Reviewed, invalid',
    'Reviewed, as system detected',
    'Reviewed, human behavior',
    'Exclude',
  ];
  public static successMessage = 'Saved Succesfully!';
  public static errorMessage = 'Error occured while saving event review.';
}
