export class CameraConstants {
  public static CAMERA_LIST_ERROR_MSG = 'Error while receiving camera list details.';
  public static GATEWAY_HIERARCHY_ERROR_MSG = 'Error while receiving gateway details.';
  public static CSV_DOWNLOAD_WAIT_MSG = 'CSV file download is in progress, Please do not move to another page.';
  public static CSV_DOWNLOAD_ERROR_MSG = 'Error while downloading event data.';
  public static PAGE_NUMBER = 1;
  public static RECORDS_PER_PAGE = 10;
  public static Id = 'camera_list';
  public static All = 'All';
  public static ACTIVE = 'Active';
  public static INACTIVE = 'Inactive';
  public static DISCONNECTED = 'Disconnected';
  public static DisplayedColumns = [
    'select',
    'gatewayName',
    'cameraName',
    'rigName',
    'redZoneName',
    'lastUpdatedStatus',
    'realTimeRigDataStatus',
  ];
  public static ColumnsForDownload = ['gatewayName', 'cameraName', 'site', 'redZoneName', 'lastUpdatedStatus', 'realTimeRigStatus'];
  public static PpeViolation = 'ppeViolation';
  public static RedZoneViolation = 'redZoneViolation';
  public static next = 'next';
  public static NoRigData = 'Red zone static';
  public static RedZoneRigData = 'Rig floor Red zone state: ';
  public static PpeRigData = 'Missing: ';
  public static Yes = 'Yes';
  public static No = 'No';
}
