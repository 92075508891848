import { Injectable } from '@angular/core';
import { DashabordChartSummary } from '../models/dashboardChartSummary';
import { Observable } from 'rxjs';
import { AssetHierarchy } from '../models/assetHierarchy';
import { DashboardAlertData } from '../models/dashabordAlertData';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constant';
import { environment } from '../../../environments/environment';
import { AlertFilter } from '../models/alertFilter';
import { EventDetails } from '../models/eventDetails';
import { CameraConstants } from '../constants/camera-constant';
import { EventChart } from '../models/eventChart';
import { countryList } from '../models/dashabordEventDetails';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public getDashabordSummaryData(
    country: string,
    startTime: string,
    endTime: string,
    showAllEvents: string
  ): Observable<DashabordChartSummary> {
    return this.http.get<DashabordChartSummary>(
      `${environment.apiBaseUrl}${ApiConstants.BASEURL}get-summary-data?country_name=${country}
&start_date=${startTime}&end_date=${endTime}&show_all_events=${showAllEvents}`
    );
  }

  // eslint-disable-next-line no-unused-vars
  public getAssetHirarchy(country: string): Observable<AssetHierarchy> {
    return this.http.get<AssetHierarchy>(`${environment.apiBaseUrl}${ApiConstants.BASEURL}get-asset-hierarchy?country_name=${country}`);
  }

  // eslint-disable-next-line no-unused-vars
  public getAlertData(countryName: string, alertFilter: AlertFilter, showAllEvents: string): Observable<DashboardAlertData> {
    return this.http.get<DashboardAlertData>(
      `${environment.apiBaseUrl}${ApiConstants.BASEURL}get-alert-data/${alertFilter.pageNumber}/
${alertFilter.recordsPerPage}?country_name=${countryName}&rig_id=${alertFilter.rigId}&workflow=${alertFilter.workflow}
&gateway_status=${alertFilter.gatewayStatus}&camera_status=${alertFilter.cameraStatus}&show_all_events=${showAllEvents}`
    );
  }

  public getDashabordEventTrendingData(
    country: string,
    startTime: string,
    endTime: string,
    timezone: string,
    showAllEvents: string
  ): Observable<EventChart> {
    return this.http.get<EventChart>(
      `${environment.apiBaseUrl}${ApiConstants.BASEURL}get-event-trend?gateway_id=&start_date=${startTime}
&end_date=${endTime}&country_name=${country}&controller_id=&time_zone=${timezone}&show_all_events=${showAllEvents}`
    );
  }

  public getCountryData(): Observable<countryList> {
    return this.http.get<countryList>(`${environment.apiBaseUrl}${ApiConstants.BASEURL}get-country-list`);
  }

  public getRigState(value: number): string {
    switch (value) {
      case 0:
        return 'Wits';
      case 1:
        return 'Gn5';
      default:
        return 'NA';
    }
  }

  public getRigEventDetails(event: EventDetails): string {
    let eventInfo = '';
    if (event.peopleInsideRedZone > 0) {
      if (event?.rigState && event.rigState !== 'NA') {
        eventInfo = event.rigState;
      } else {
        eventInfo = CameraConstants.NoRigData;
      }
    } else if (event.ppeViolations > 0) {
      /** For PPE the rigstate value will comes in comment */
      if (event.comment) {
        const comment = event.comment.toString().replace(/[&\/\\#+()$~%.'":*?<>{}]/g, '');
        const missingEvents = comment.split(',');
        missingEvents.sort((a, b) => (a.replace(/\s/g, '') > b.replace(/\s/g, '') ? 1 : -1));
        if (missingEvents.length > 0) {
          missingEvents.map((e: string, index: number) => {
            const missingPPE = this.getMissingPpe(e.replace(/\s/g, ''));
            if (missingPPE !== '') {
              eventInfo = eventInfo + missingPPE;
              if (index < missingEvents.length - 1) {
                eventInfo = eventInfo + ', ';
              }
            }
          });
        } else {
          eventInfo = this.getMissingPpe(event.comment.replace(/\s/g, ''));
        }
      } else {
        eventInfo = CameraConstants.NoRigData;
      }
    } else {
      eventInfo = CameraConstants.NoRigData;
    }

    return eventInfo;
  }

  public getMissingPpe(value: string): string {
    switch (value) {
      case 'HELMET_DETECTION':
        return 'Helmet';
      case 'COVERALL_DETECTION':
        return 'Coverall';
      case 'GLOVE_DETECTION':
        return 'Glove';
      case 'VEST_DETECTION':
        return 'High-visibility vest';
      default:
        return '';
    }
  }

  public downloadZipFile(url: string, controllerId: string): void {
    const blob = new Blob([url], {
      type: 'application/zip',
    });
    const bloburl = window.URL.createObjectURL(blob);
    const filename = 'SLB Edge-Vision Intelligence-' + controllerId;
    const dwldLink = document.createElement('a');

    dwldLink.setAttribute('href', bloburl);
    dwldLink.setAttribute('download', filename + '.zip');
    dwldLink.setAttribute('target', '_blank');
    dwldLink.click();
    dwldLink.remove();
  }

  public unsubscribeEmail(user_email: string, org_id: number): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}${ApiConstants.BASEURL}unsusbscribe_email/${user_email}/${org_id}`);
  }
}
