import { Component, OnInit } from '@angular/core';
import { NavItem } from '@slb-dls/angular-material/shared';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss'],
})
export class UserSettingComponent implements OnInit {
  public routerLinks: Array<any>;
  public secondaryNavigationItems: NavItem[];

  constructor() {
  }

  ngOnInit(): void {
    this.routerLinks = [
      {
        label: 'Units',
        routerLink: 'unit',
        active: true,
      },
    ];
    this.secondaryNavigationItems = JSON.parse(JSON.stringify(this.routerLinks));
  }
}
