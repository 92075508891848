import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { ConfirmationInput } from '../components/confirm-dialog/confirmation-input';
import { ConfirmationResult } from '../components/confirm-dialog/confirmation-result';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { EventReviewComponent } from '../../dashboard/event-review/event-review.component';
import { EventDetails } from '../../shared/models/eventDetails';
import { ZoneSettingComponent } from '../../zone-manager/zone-setting/zone-setting.component';

@Injectable({
  providedIn: 'root',
})
export class PopUpService {
  constructor(private dialogRef: MatDialog) {}

  public async confirmChanges(message: string): Promise<ConfirmationResult> {
    const input: ConfirmationInput = { message };

    const dialogRef = this.dialogRef.open(ConfirmDialogComponent, { disableClose: true, data: input });

    return this.closeDialog(dialogRef);
  }

  public async openZoneSetting(cameraStatus: string): Promise<ConfirmationResult> {
    const dialogRef = this.dialogRef.open(ZoneSettingComponent, {
      width: '45vw',
      height: window.innerWidth >= 1400 ? '75vh' : '95vh',
      disableClose: true,
      data: {
        cameraStatus,
      },
    });

    return this.closeDialog(dialogRef);
  }

  public async openEditReviewConfiguration(eventDetails: EventDetails[]): Promise<ConfirmationResult> {
    const dialogRef = this.dialogRef.open(EventReviewComponent, {
      width: '409px',
      minHeight: '350px',
      disableClose: true,
      data: eventDetails,
    });

    return this.closeDialog(dialogRef);
  }

  private async closeDialog(dialogRef: MatDialogRef<any, any>): Promise<ConfirmationResult> {
    try {
      return await lastValueFrom(dialogRef.afterClosed());
    } catch (_error) {
      return ConfirmationResult.Cancel;
    }
  }
}
