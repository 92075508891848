<div class="carousel-container" *ngIf="media && media.length">
  <div class="fullscreen-image-container" *ngFor="let item of media; let i = index" [ngClass]="{ active: selectedIndex === i }">
    <ng-container [ngSwitch]="item?.type">
      <div *ngSwitchCase="'image'">
        <img
          *ngIf="item?.type === 'image' && item?.fileUrl && !item.fileUrl.includes('mp4'); else errorImage"
          src="{{ item?.fileUrl }}/?token={{ sessionToken }}"
          [src]="item.fileUrl + '/?token=' + sessionToken"
          (error)="fullScreenImg.src = '../../assets/no-preview.svg'"
          #fullScreenImg
          appImageStyle
          class="fullscreen-image-src fade"
          [ngClass]="{ active: selectedIndex === i }"
          id="latest_camera_popover_fullscreen_image_src" />
        <mat-icon
          class="fullscreen-icon"
          id="carousel_fullscreen_icon"
          (click)="showImageInFullscreen(item.fileUrl)"
          title="Fullscreen"
          svgIcon="fullscreen"></mat-icon>
        <ng-template #errorImage>
          <img
            *ngIf="item?.type === 'image'"
            src="../../assets/no-preview.svg"
            appImageStyle
            [ngClass]="{ active: selectedIndex === i }"
            class="fullscreen-image-src fade"
            id="latest_camera_popover_fullscreen_no_image_src" />
        </ng-template>
      </div>
      <div *ngSwitchCase="'video'">
        <video
          id="latest_camera_popover_showControls"
          #videoPlayer
          class="video-player"
          autoplay
          class="fullscreen-video-src fade"
          loop
          controls
          [ngClass]="{ active: selectedIndex === i }">
          <source src="{{ item?.fileUrl }}/?token={{ sessionToken }}" type="video/mp4" />
          <track id="enTrack" label="English" kind="subtitles" srclang="en-us" default />
        </video>
      </div>
      <div *ngSwitchDefault></div>
    </ng-container>
  </div>
  <div *ngIf="showIndicators" class="corousel-dot-conatiner">
    <span
      class="dot"
      *ngFor="let dot of media; let i = index"
      [ngClass]="{ 'active-dot': selectedIndex === i }"
      (click)="setSelectedIndex(i)">
    </span>
  </div>
  <button *ngIf="showControls && selectedIndex !== 0" class="btn-corousel btn-prev">
    <mat-icon
      class="icon-corousel icon-prev"
      svgIcon="arrow-left-2"
      aria-hidden="false"
      aria-label="Example thumbs up SVG icon"
      (click)="onPrevClick()"></mat-icon>
  </button>
  <button *ngIf="showControls && selectedIndex !== media.length - 1" class="btn-corousel btn-next">
    <mat-icon
      class="icon-corousel icon-next"
      svgIcon="arrow-right-2"
      aria-hidden="false"
      aria-label="Example thumbs up SVG icon"
      (click)="onNextClick()"></mat-icon>
  </button>
</div>
<div class="img-background-main" *ngIf="isFullscreen">
  <div [ngClass]="{ show: isFullscreen, hide: !isFullscreen }">
    <img [src]="fullScreenImgSrc" />
    <button class="close-fullscreen" (click)="hideImageInFullscreen()">
      <mat-icon svgIcon="close" class="close-icon" id="carousel_close_fullscreen">close</mat-icon>
    </button>
  </div>
</div>
