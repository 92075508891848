import { Injectable, signal } from '@angular/core';
import { CameraDetails, CameraProfileDetails } from '../models/cameraDetails';
import { BehaviorSubject, EMPTY, Observable, expand, scan } from 'rxjs';
import { EventData, EventDataIteration, EventDetails } from '../models/eventDetails';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constant';
import { environment } from '../../../environments/environment';
import { EventDetailsDto } from '../models/eventDetailsDto';
import { ZoneConfig } from '../models/zoneSetting';
import { toObservable } from '@angular/core/rxjs-interop';
import { EventChart } from '../models/eventChart';
import { EventReviewConstants } from '../constants/event-review-constants';
import { DashboardConstants } from '../constants/dashboard-constants';
import { CameraConstants } from '../constants/camera-constant';
import { DatePipe } from '@angular/common';
import { DashboardService } from './dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class CameraProfileService {
  public eventData$: Observable<boolean>;
  public eventData = new BehaviorSubject<boolean>(true);
  public isAdmin$: Observable<string>;
  public isAdmin = new BehaviorSubject<string | ''>(sessionStorage.getItem('isAdmin') ?? '');
  public selectedEventTime$: Observable<string | ''>;
  public selectedZoneId$: Observable<ZoneConfig>;
  public sideNavClosed$: Observable<boolean>;
  public sideNavClosed = signal<boolean>(true);
  public cameraWorkflow = signal<string>('');
  public cameraWorkflow$: Observable<string>;
  public profileData$: Observable<CameraDetails>;

  private selectedEventTimeSource = new BehaviorSubject<string | ''>(sessionStorage.getItem('selectedCameraEventDate') ?? '');
  private selectedZoneIdSource = new BehaviorSubject<ZoneConfig>({} as ZoneConfig);
  private selectedProfileDataSource = new BehaviorSubject<CameraDetails>({} as CameraDetails);
  private baseUrl: string;

  constructor(private http: HttpClient, private datePipe: DatePipe, private dashboardService: DashboardService) {
    this.baseUrl = environment.apiBaseUrl;
    this.eventData$ = this.eventData.asObservable();
    this.isAdmin$ = this.isAdmin.asObservable();
    this.selectedEventTime$ = this.selectedEventTimeSource.asObservable();
    this.selectedZoneId$ = this.selectedZoneIdSource.asObservable();
    this.sideNavClosed$ = toObservable(this.sideNavClosed);
    this.cameraWorkflow$ = toObservable(this.cameraWorkflow);
    this.profileData$ = this.selectedProfileDataSource.asObservable();
  }

  public getCameraDetails(
    gatewayId: string,
    controllerId: string,
    reviewCategory: string,
    startTime: string,
    endTime: string,
    showAllEvents: string
  ): Observable<CameraProfileDetails> {
    return this.http.get<CameraProfileDetails>(
      /* eslint-disable max-len */
      `${this.baseUrl}${ApiConstants.BASEURL}get-camera-summary/${gatewayId}/${controllerId}?start_date=${startTime}&end_date=${endTime}&review_category=${reviewCategory}&show_all_events=${showAllEvents}`
    );
  }

  public getEventTrendDetails(
    gatewayId: string,
    controllerId: string,
    startTime: string,
    endTime: string,
    countryName: string,
    reviewCategory: string,
    timeZone: string,
    showAllEvents: string
  ): Observable<EventChart> {
    return this.http.get<EventChart>(
      `${this.baseUrl}${ApiConstants.BASEURL}get-event-trend?gateway_id=${gatewayId}
&start_date=${startTime}&end_date=${endTime}&review_category=${reviewCategory}
&country_name=${countryName}&controller_id=${controllerId}&time_zone=${timeZone}&show_all_events=${showAllEvents}`
    );
  }

  public getCameraEventData(
    gatewayId: string,
    controllerId: string,
    startTime: string,
    endTime: string,
    pageNumber: number,
    recordsPerPage: number,
    reviewCategory: string,
    event: string,
    showAllEvents: string
  ): Observable<EventData> {
    return this.http.get<EventData>(
      `${this.baseUrl}${ApiConstants.BASEURL}get-camera-alert-list/${gatewayId}/${controllerId}
/${pageNumber}/${recordsPerPage}?start_date=${startTime}&end_date=${endTime}&review_category=${reviewCategory}&event=${event}&show_all_events=${showAllEvents}`
    );
  }

  public downloadMediaAsZip(gatewayId: string, controllerId: string, eventIds: number[], isFileApi: boolean): Observable<any> {
    const param = {
      gatewayId,
      controllerId,
      eventIds,
    };
    let fileUrl = ApiConstants.FILEBASEURL;
    if (isFileApi) {
      fileUrl = ApiConstants.FILEAPIBASEURL;
    }

    return this.http.post(`${this.baseUrl}${fileUrl}download-alert-media-data`, param, {
      responseType: 'arraybuffer',
    });
  }

  public saveEventReview(eventDetails: EventDetailsDto): Observable<string> {
    // post API call
    return this.http.post<string>(`${this.baseUrl}${ApiConstants.BASEURL}save-event-review`, eventDetails);
  }

  public getEventData(): void {
    this.eventData.next(true);
  }

  public setAdmin(admin: string): void {
    this.isAdmin.next(admin);
  }

  public setEventTime(EventTime: string | ''): void {
    this.selectedEventTimeSource.next(EventTime);
  }

  public setZoneId(zoneConfig: ZoneConfig): void {
    this.selectedZoneIdSource.next(zoneConfig);
  }

  public setProfileData(profileData: CameraDetails): void {
    this.selectedProfileDataSource.next(profileData);
  }

  public getAllEventData(
    gatewayId: string,
    controllerId: string,
    startTime: string,
    endTime: string,
    pageNumber: number,
    recordsPerPage: number,
    reviewCategory: string,
    event: string,
    showAllEvent: string
  ): Observable<EventDataIteration> {
    let totalPages = 0;

    return this.getCameraEventData(
      gatewayId,
      controllerId,
      startTime,
      endTime,
      pageNumber,
      recordsPerPage,
      reviewCategory,
      event,
      showAllEvent
    ).pipe(
      expand((response: EventData) => {
        const dataResponse: EventData = JSON.parse(JSON.stringify(response));
        if (dataResponse.totalRecords !== 0 && totalPages !== pageNumber) {
          return this.getCameraEventData(
            gatewayId,
            controllerId,
            startTime,
            endTime,
            ++pageNumber,
            recordsPerPage,
            reviewCategory,
            event,
            showAllEvent
          );
        } else {
          pageNumber = 1;
          this.getCameraEventData(
            gatewayId,
            controllerId,
            startTime,
            endTime,
            pageNumber,
            recordsPerPage,
            reviewCategory,
            event,
            showAllEvent
          );

          return EMPTY;
        }
      }),
      scan((accumulatedData: EventDataIteration, currentData: EventData, index: number) => {
        if (Object.keys(accumulatedData).length === 0) {
          totalPages = Math.round(currentData.totalRecords / recordsPerPage);
          totalPages = currentData.totalRecords % recordsPerPage !== 0 ? ++totalPages : totalPages;
          accumulatedData = {
            ...currentData,
            index,
            totalPages,
          };
        } else {
          accumulatedData.data = [...currentData.data];
          accumulatedData.index = index;
        }

        return accumulatedData;
      }, {} as EventDataIteration)
    );
  }

  public convertToCsv(prevCsvData: string, objArray: EventDetails[], headerList: string[], currentZone: string): string {
    let str = '';
    if (!prevCsvData) {
      let row = '';
      headerList.forEach((value: string) => {
        row += value.charAt(0).toUpperCase() + value.slice(1) + ',';
      });
      row = row.slice(0, -1);
      str += row + '\r\n';
    }

    str += this.extractData(objArray, headerList, currentZone);

    return str;
  }

  private extractData(objArray: EventDetails[], headerList: string[], currentZone: string): string {
    let str = '';

    objArray.forEach((eventValues: EventDetails) => {
      let line = '';
      headerList.forEach((header: string) => {
        line += this.getPropertyValue(eventValues, header, currentZone) + ',';
      });
      line = line.replace(/,\s*$/, '');
      str += line + '\r\n';
    });

    return str;
  }

  private getPropertyValue(value: EventDetails, propertyValue: string, currentZone: string): string {
    switch (propertyValue) {
      case 'peopleInsideRedZone':
        return value.ppeViolations ? '' : value.peopleInsideRedZone.toString();

      case 'reason':
        if (value.rigState) {
          value.rigState = this.dashboardService.getRigEventDetails(value);
        }
        if (
          !value.rigState ||
          value.rigState === CameraConstants.NoRigData ||
          (value && value.peopleInsideRedZone === 0 && value.ppeViolations === 0)
        ) {
          return CameraConstants.NoRigData;
        } else {
          let tag = value && value.peopleInsideRedZone > 0 ? CameraConstants.RedZoneRigData : CameraConstants.PpeRigData;
          tag += value.rigState;
          tag = tag.replace(/,/g, '|');

          return tag;
        }
      case 'isReviewed':
        return value.isReviewed ? CameraConstants.Yes : CameraConstants.No;

      case 'flag':
        return value.flag !== null ? EventReviewConstants.FlagsData[value.flag] : '';

      case 'category':
        return value.category !== null ? EventReviewConstants.Categories[value.category].replace(/,/g, '-') : '';

      case 'reviewComment':
        const reviewValue = value[propertyValue] ? value[propertyValue].replace(/\n/g, ' ') : '';

        return reviewValue;

      case 'type':
        return value.peopleInsideRedZone > 0 ? DashboardConstants.EVENTTYPE[1] : DashboardConstants.EVENTTYPE[0];

      case 'timestamp':
        const dateconversion = this.datePipe.transform(new Date(value.timestamp), 'MM/dd/yyyy HH:mm:ss a', currentZone);

        return dateconversion ? dateconversion : '';

      case 'imageFilename':
        const image = value.media.find(x => x.type === 'image');

        return image ? image.fileUrl.split('download/')[1] : '';

      case 'videoFilename':
        const video = value.media.find(x => x.type === 'video');

        return video ? video.fileUrl.split('download/')[1] : '';

      default:
        return value ? (value[propertyValue as keyof EventDetails] as string) : '';
    }
  }
}
